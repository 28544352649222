/* istanbul ignore file */
import { BaseBlock } from './BaseBlock';
import { BlockType } from '../BlockType';
import { Page } from '../Page';
import { MainCategory, SubCategory } from '../../Category';
import { SortDirection, SortType } from '../SortOption';

export enum PageListViewType {
  GRID = 'grid',
  LIST = 'list',
  SIMPLE = 'simple',
}

export enum PageListLayout {
  TEXT_ON_IMAGE = 'img',
  IMAGE_TOP = 'img-top',
  IMAGE_TOP_NO_MARGIN = 'img-top no-margin',
}

export interface PageListItem {
  id: number;
  site_id?: number;
  visible?: boolean;
  local?: {
    description?: string;
    highlight?: boolean;
    skin?: string;
    title?: string;
    imageTopPosition?: boolean;
  } & Page['local'];
}

export interface PageListBlock extends BaseBlock {
  layout?: PageListLayout;
  view: PageListViewType;
  displayCategory?: boolean;
  displayPosition?: boolean;
  displayPublishedDate?: boolean;
  displayDescription?: boolean;
  displayLocation?: boolean;
  displayMuseum?: boolean;
  displayInsertTitle?: boolean;
  highlightAll?: boolean;
  grid33?: boolean;
  removeLeftPadding?: boolean;
  showAllLinkPage?: Page | null;
  showAllLinkType?: 'button' | 'text';
}

export interface PageListAutoBlock extends PageListBlock {
  type: BlockType.PAGE_LIST_AUTO;
  displayType: 'pageTree' | 'categories';
  searchPathPageId?: number;
  searchLevel?: 'siblings' | 'children';
  maxSearchResults?: number;
  order?: SortDirection;
  orderBy?: SortType.TITLE | SortType.UPDATED_AT | SortType.PUBLISHED_AT;
  mainCategory?: MainCategory;
  subCategories?: Array<SubCategory>;
  siteIds?: string;
}

export interface PageListManualBlock extends PageListBlock {
  type: BlockType.PAGE_LIST_MANUAL;
  items: Array<PageListItem>;
}
