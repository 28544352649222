import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SelectInput from '@/components/forms/SelectInput';
import { useController } from 'react-hook-form';
import { FormLabel } from '@mui/material';
import { MaterialSymbol } from '@/components/MaterialSymbol';
import Container from '../../../../components/Container';
import { BlockComponent } from '../../../lib/declarations/EditorComponentTypes';
import { BlockPath, useCurrentBlock } from '../../CurrentBlockProvider';
import { useControlledFieldArray } from '../../../../hooks/useControlledFieldArray';
import { BlockSpecificPage } from '../../../lib/declarations/BlockSpecificPage';
import { DMManualBlockModel, DMViewType } from '../../../../declarations/models/blocks/DMBlock';
import FinderButton, { FinderType } from '../../../../components/Finder/FinderButton';
import { GenericMedia } from '../../../../declarations/GenericMedia';
import { DMArtifact, DMMediaModel } from '../../../../declarations/models/DMMediaModel';
import { settingsAreaStyle } from '../EmployeeBlocks/EmployeesAutoBlock';
import DMBlockItem from './DMBlockItem';
import { DMObjectType } from '../../../../declarations/models/DMObjectType';
import TextInput from '../../../../components/forms/TextInput';
import { Api } from '../../../../services/Api';
import SwitchInput from '../../../../components/forms/SwitchInput';
import SingleDMItem from './SingleDMItem';
import { getMapSettings } from './DMAutoBlock';

export const DMManualBlock: BlockComponent = () => {
  const { t } = useTranslation('components');
  const { blockPath, block } = useCurrentBlock();
  const currentBlock = block as DMManualBlockModel;
  const [museumArtifact, setMuseumArtifact] = useState<DMArtifact>();

  const gridSizeOptions = ['auto', '3', '4', '5', '6'];

  const {
    fields: dmItems,
    append: appendDm,
    insert: insertDm,
    move: moveDm,
    remove: removeDm,
  } = useControlledFieldArray<BlockSpecificPage<DMManualBlockModel>, `${BlockPath}.dmItems`, 'itemHash'>({
    name: `${blockPath}.dmItems`,
  });

  const {
    fields: vrItems,
    append: appendVr,
    insert: insertVr,
    move: moveVr,
    remove: removeVr,
  } = useControlledFieldArray<BlockSpecificPage<DMManualBlockModel>, `${BlockPath}.vrItems`, 'itemHash'>({
    name: `${blockPath}.vrItems`,
  });

  const {
    field: { value: museum, onChange: setMuseum },
  } = useController<BlockSpecificPage<DMManualBlockModel>, `${typeof blockPath}.museum`>({
    name: `${blockPath}.museum`,
  });

  const handleAddDmItem = (item: DMMediaModel | null) => {
    if (item?.['artifact.uniqueId'])
      appendDm({ uniqueId: item['artifact.uniqueId'], title: item['artifact.ingress.title'] });
  };

  const handleAddVrItem = (item: DMMediaModel | null) => {
    if (item?.['artifact.uniqueId']) appendVr({ uniqueId: item['artifact.uniqueId'] });
  };

  const handleSetMuseum = (item: DMMediaModel | null) => {
    if (item?.['artifact.uniqueId'])
      setMuseum({ uniqueId: item['artifact.uniqueId'], title: item['artifact.ingress.title'] });
  };

  useEffect(() => {
    if (museum) {
      if (museum.uniqueId) {
        const ctx = Api.getDMArtifact(museum.uniqueId);

        ctx
          .fetchDirect(null)
          .then((res) => !!res && setMuseumArtifact(res))
          .finally(ctx.abort);
      }
    } else setMuseumArtifact(undefined);
  }, [museum]);

  return (
    <Container gap={2} column top left fullWidth>
      <Container column gap={2} fullWidth sx={settingsAreaStyle}>
        <FinderButton
          type={FinderType.DM}
          disabled
          multiSelect={false}
          overrideText={t('DMBlock.DMMuseumLabel')}
          overrideIcon={<MaterialSymbol name='museum' />}
          finderProps={{
            onSelectionConfirmed: (items: Array<GenericMedia<DMMediaModel>>) =>
              !!items.length && handleSetMuseum(items[0].source),
            objectType: DMObjectType.MUSEUM,
          }}
        />
        {!!museumArtifact && (
          <>
            <SingleDMItem dmArtifact={museumArtifact} onRemove={() => setMuseum(null)} />
            <Container left column fullWidth>
              <FormLabel>{t('DMBlock.AlternativeFields')}:</FormLabel>
              <Container column gap={2} fullWidth>
                <TextInput path={`${blockPath}.museumData.altTitle`} label={t('DMBlock.AltTitleLabel')} size='small' />
                <TextInput
                  path={`${blockPath}.museumData.altDescription`}
                  label={t('DMBlock.AltDescriptionLabel')}
                  multiline
                  minRows={1}
                  maxRows={8}
                  size='small'
                />
                {/* <SwitchInput path={`${blockPath}.museumData.wideDisplay`} label={t('DMBlock.WideDisplayLabel')} /> */}
              </Container>
            </Container>
          </>
        )}
      </Container>
      <Container column fullWidth sx={settingsAreaStyle}>
        <FinderButton
          type={FinderType.DM}
          overrideText={t('DMBlock.DMItemsLabel')}
          finderProps={{
            onSelectionConfirmed: (items: Array<GenericMedia<DMMediaModel>>) =>
              items.forEach((item) => handleAddDmItem(item.source)),
            hasLocationDep: currentBlock?.view === DMViewType.MAP,
          }}
        />
        {!!dmItems.length && (
          <Container column fullWidth gap={0}>
            {dmItems.map((item, i) => (
              <DMBlockItem
                key={item.uniqueId}
                index={i}
                onReorder={moveDm}
                onAdd={(itemToAdd, addAtIndex) => itemToAdd && insertDm(addAtIndex, itemToAdd)}
                onRemove={removeDm}
              />
            ))}
          </Container>
        )}
      </Container>

      <Container column fullWidth sx={settingsAreaStyle}>
        <TextInput path={`${blockPath}.dmLink`} defaultValue='' label={t('DMBlock.DmLink')} size='small' />
        <TextInput path={`${blockPath}.dmLinkText`} defaultValue='' label={t('DMBlock.DmLinkText')} size='small' />
      </Container>

      <Container column fullWidth sx={settingsAreaStyle}>
        <SelectInput
          hideNoSelectionOption
          defaultValue='auto'
          options={gridSizeOptions}
          getOptionKey={(gridSize) => `size-${gridSize}`}
          getOptionLabel={(gridSize) => `${gridSize}`}
          path={`${blockPath}.gridSize`}
          label={t('CardModuleBlock.GridSizeLabel')}
        />
      </Container>

      {/*
      <Container column fullWidth sx={settingsAreaStyle}>
        <FinderButton
          type={FinderType.DM}
          overrideIcon={<MaterialSymbol name='view_in_ar' />}
          overrideText={t('DMBlock.VRItemsLabel')}
          finderProps={{
            onSelectionConfirmed: (items: Array<GenericMedia<DMMediaModel>>) =>
              items.forEach((item) => handleAddVrItem(item.source)),
            objectType: DMObjectType.VIRTUAL,
          }}
        />
        {!!vrItems.length && (
          <Container column fullWidth gap={0}>
            {vrItems.map((item, i) => (
              <DMBlockItem
                key={item.uniqueId}
                index={i}
                onReorder={moveVr}
                onAdd={(itemToAdd, addAtIndex) => itemToAdd && insertVr(addAtIndex, itemToAdd)}
                onRemove={removeVr}
              />
            ))}
          </Container>
        )}
      </Container>
       */}
      {/* currentBlock?.view === DMViewType.MAP && getMapSettings(t, blockPath) */}
    </Container>
  );
};

export default DMManualBlock;
