import { BaseBlock } from './BaseBlock';
import { BlockType } from '../BlockType';

export enum KPViewType {
  GRID = 'grid',
  LIST = 'list',
  MAP = 'map',
}

export enum PresDisplayType {
  ALL_POINTS = 'all_points',
  PICK_POINTS = 'pick_points',
  SELECTION = 'selection',
}

type LocalizedField = { [lngKey: string]: string };

export interface KPDataItem {
  id: number;
  title?: string | LocalizedField;
  size?: 'M' | 'L';
}

export interface KPSinglePresItem extends KPDataItem {
  articles: Array<KPDataItem>;
  displayType: PresDisplayType;
  selectionIndex: number;
}

export interface KPOwnerItem extends KPDataItem {
  listPresentations: boolean;
  altTitle?: string;
  altDescription?: string;
}

export interface KPBlockModel extends BaseBlock {
  type: BlockType.KP;
  view: KPViewType;
  width: string;
  presentation?: KPSinglePresItem;
  articles: Array<KPDataItem>;
  integratedView?: boolean;
  gridSize?: string;
}

export interface KPOwnerBlockModel extends BaseBlock {
  type: BlockType.KP_OWNER;
  width: string;
  owners: Array<KPOwnerItem>;
  presentations: Array<KPDataItem>;
}
