import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useController, useFormContext } from 'react-hook-form';
import { Checkbox, FormControl, FormControlLabel } from '@mui/material';
import { useStore } from '../../components/store/Store';
import { Site } from '../../declarations/models/Site';
import SettingsInputContainer from '../../components/SettingsInputContainer';
import Container from '../../components/Container';
import TextInput from '../../components/forms/TextInput';

export interface LanguageInfoSettingsProps {
  locale: string;
}

export const LanguageInfoSettings: FC<LanguageInfoSettingsProps> = ({ locale }) => {
  const { t } = useTranslation('components');
  const { state } = useStore();
  const isSuperAdmin = state.currentUser?.user?.is_super_admin;

  const {
    formState: { dirtyFields },
  } = useFormContext();
  const isDirty = Object.keys(dirtyFields).length > 0;

  const {
    field: { value: defaultLanguage, onChange: onChangeDefaultLanguage },
  } = useController<Site, `default_language`>({
    name: `default_language`,
    defaultValue: '',
  });

  return (
    <>
      {/*
      <SettingsInputContainer>
        <Container px={2}>
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={defaultLanguage === locale}
                  onChange={(e, checked) => {
                    onChangeDefaultLanguage(checked ? locale : '');
                  }}
                />
              }
              label={t('Settings.LanguageSettings.LanguageInfoSettings.defaultLanguage')}
              labelPlacement='end'
              disabled={defaultLanguage === locale && !isDirty}
            />
          </FormControl>
        </Container>
      </SettingsInputContainer>
       */}
      <SettingsInputContainer title={t('Settings.LanguageSettings.LanguageInfoSettings.pageInfo.title')}>
        <Container fullWidth spaceBetween gap={2}>
          <TextInput
            path='content.name'
            defaultValue=''
            label={t('Settings.LanguageSettings.LanguageInfoSettings.pageInfo.name')}
          />
          <TextInput
            path='content.description'
            defaultValue=''
            label={t('Settings.LanguageSettings.LanguageInfoSettings.pageInfo.description')}
          />
        </Container>
        {isSuperAdmin && (
          <Container fullWidth spaceBetween gap={2}>
            <div className='superadmin' style={{ backgroundColor: '#fbe6ff', padding: '1em', width: '100%' }}>
              <TextInput
                path='content.overrideLocale'
                defaultValue=''
                label={t('Settings.LanguageSettings.LanguageInfoSettings.pageInfo.overrideLocale')}
              />
            </div>
          </Container>
        )}
      </SettingsInputContainer>
    </>
  );
};

export default LanguageInfoSettings;
