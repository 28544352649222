import React, { CSSProperties, FC } from 'react';
import { Collapse, Typography, Box } from '@mui/material';
import { useController, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import TextInput from '../../components/forms/TextInput';
import Styles from '../../assets/js/Styles';
import { Page } from '../../declarations/models/Page';
import Container from '../../components/Container';
import { SectionType } from '../../declarations/models/SectionType';
import SelectInput from '../../components/forms/SelectInput';
import {
  SectionArrows,
  SectionBackgroundPosition,
  SectionModifier,
  SectionPadding,
  SectionSpacing,
  SectionTextColor,
} from '../../declarations/models/Section';

import CheckboxInput from '../../components/forms/CheckboxInput';
import RadioInput from '../../components/forms/RadioInput';
import { CssFilter } from '../../declarations/models/CssFilter';
import { SectionPath, useCurrentSection } from './CurrentSectionProvider';
import Section from '../../components/Section/Section';
import { GenericMedia } from '../../declarations/GenericMedia';
import FinderButton, { FinderType } from '../../components/Finder/FinderButton';
import { M24MediaModel } from '../../declarations/models/M24MediaModel';
import { DMMediaModel } from '../../declarations/models/DMMediaModel';
import { prepareMediaItemAsDMMediaModel, prepareMediaItemAsM24MediaModel } from '../../utils/MediaUtils';
import ImagePreview from '../../components/ImagePreview';
import DeleteButton from '../../components/DeleteButton';

const ARROW_OPTIONS: Array<SectionArrows> = ['allways', 'first', 'none'];
const BACKGROUND_POSITION_OPTIONS: Array<SectionBackgroundPosition> = ['top', 'center', 'contain'];
const TEXT_COLOR_OPTIONS: Array<SectionTextColor> = ['light', 'dark'];
const SPACING_OPTIONS: Array<SectionSpacing> = ['auto', '0', '1', 'line'];
const PADDING_OPTIONS: Array<SectionPadding> = ['auto', '0', '1', '2', '3', '4', '5'];
const MODIFIER_OPTIONS: Array<SectionModifier> = ['grid33'];
const FILTER_OPTIONS: Array<CssFilter> = Object.values(CssFilter);

export const imgStyle: CSSProperties = {
  maxWidth: '300px',
  minWidth: '250px',
  minHeight: '150px',
  maxHeight: '200px',
};

export const noImgStyle: CSSProperties = {
  ...imgStyle,
  backgroundColor: 'white',
  border: `1px dashed ${Styles.Colors.DARK_GREY}`,
  borderRadius: Styles.Dimensions.RADIUS_ROUNDNESS_DEFAULT,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const CommonSectionContent: FC = () => {
  const { t: tComp } = useTranslation('components');
  const { t: tCommon } = useTranslation('common');
  const { sectionPath, sectionType } = useCurrentSection();
  const isIntroSection = useWatch<Page>({ name: `${sectionPath}.introSection`, defaultValue: false });

  const {
    field: { value: image, onChange: onChangeImage },
  } = useController<Page, `${SectionPath}.mediaobject`>({ name: `${sectionPath}.mediaobject` });

  // FIXME: Parameterize the sections-specific content
  return (
    <Section
      sectionId={`${sectionPath}-settings`}
      title={tComp('CommonSectionContent.SectionSettings')}
      disableElevation
      color={Styles.Colors.BLOCK_SETTINGS}>
      <Container sx={{ maxHeight: '100%', maxWidth: '100%' }} gap={2} column top left fullWidth>
        <Container gap={1} top spaceBetween fullWidth>
          <TextInput<Page> path={`${sectionPath}.title`} label={tCommon('title')} defaultValue='' />

          {(sectionType === SectionType.GRID_ROW || sectionType === SectionType.PAGE_GRID) && (
            <Box display='flex' style={{ width: '60%' }}>
              <CheckboxInput<Page>
                path={`${sectionPath}.visibletitle`}
                label={tComp('CommonSectionContent.FormLabels.ToggleVisibleTitle')}
                defaultValue={false}
              />
              <CheckboxInput<Page>
                path={`${sectionPath}.centerTitle`}
                label={tComp('CommonSectionContent.FormLabels.ToggleCenterTitle')}
                defaultValue={false}
              />
            </Box>
          )}
        </Container>

        <Container gap={1} top spaceBetween fullWidth>
          <TextInput<Page>
            path={`${sectionPath}.tocId`}
            label={tComp('CommonSectionContent.FormLabels.AnchorID')}
            defaultValue=''
          />

          <TextInput<Page>
            path={`${sectionPath}.cssClass`}
            label={tComp('CommonSectionContent.FormLabels.CssClass')}
            defaultValue=''
          />
        </Container>

        {!isIntroSection && (
          <Container gap={1} top spaceBetween fullWidth>
            <SelectInput<Page, SectionPadding>
              options={PADDING_OPTIONS}
              getOptionKey={String}
              getOptionLabel={(padding) => tComp(`CommonSectionContent.padding.${padding}`)}
              path={`${sectionPath}.topPadding`}
              label={tComp('CommonSectionContent.FormLabels.PaddingTop')}
              defaultValue='auto'
              fullWidth
            />

            <SelectInput<Page, SectionPadding>
              options={PADDING_OPTIONS}
              getOptionKey={String}
              getOptionLabel={(padding) => tComp(`CommonSectionContent.padding.${padding}`)}
              path={`${sectionPath}.bottomPadding`}
              label={tComp('CommonSectionContent.FormLabels.PaddingBottom')}
              defaultValue='auto'
              fullWidth
            />
          </Container>
        )}

        {sectionType === SectionType.GRID_ROW && (
          <>
            <Container gap={1} top spaceBetween fullWidth>
              <SelectInput<Page, SectionSpacing>
                options={SPACING_OPTIONS}
                getOptionKey={String}
                getOptionLabel={(spacing) => tComp(`CommonSectionContent.spacing.${spacing}`)}
                path={`${sectionPath}.spacing`}
                label={tComp('CommonSectionContent.FormLabels.Spacing')}
                defaultValue='auto'
                fullWidth
              />
              <SelectInput<Page, SectionModifier>
                options={MODIFIER_OPTIONS}
                getOptionKey={String}
                getOptionLabel={(modifier) => tComp(`CommonSectionContent.modifier.${modifier}`)}
                path={`${sectionPath}.modifier`}
                label={tComp('CommonSectionContent.FormLabels.Modifier')}
                defaultValue=''
              />
            </Container>

            <CheckboxInput<Page>
              path={`${sectionPath}.removeLeftPadding`}
              label={tComp('CommonSectionContent.FormLabels.RemoveLeftPadding')}
              defaultValue={false}
            />
          </>
        )}

        {sectionType === SectionType.ARTICLE_BODY && (
          <CheckboxInput<Page>
            path={`${sectionPath}.introSection`}
            label={tComp('CommonSectionContent.FormLabels.ToggleIntrosection')}
            defaultValue={false}
          />
        )}

        <Collapse in={sectionType === SectionType.ARTICLE_BODY && !!isIntroSection} sx={{ width: '100%' }}>
          <Container gap={2} column top left fullWidth fullHeight>
            {tCommon('IntroSection.about')}
            <Typography fontWeight='bold'>{tComp('CommonSectionContent.FormLabels.BackgroundMedia')}</Typography>
            <Container gap={8}>
              <Container left top>
                {image ? (
                  <Container column top>
                    <ImagePreview mediaObject={image} imgStyle={imgStyle} />
                    <DeleteButton onConfirm={() => onChangeImage(null)} deleteLabel='remove' iconColor='secondary' />
                  </Container>
                ) : (
                  <Container column>
                    <div style={noImgStyle}>Ingen bilde valgt</div>
                  </Container>
                )}
              </Container>
              <Container right column>
                <SelectInput<Page, CssFilter>
                  options={FILTER_OPTIONS}
                  getOptionKey={String}
                  getOptionLabel={(filter) => tComp(`CommonSectionContent.filter.${filter}`)}
                  path={`${sectionPath}.filter1`}
                  label={tComp('CommonSectionContent.FormLabels.Filter1')}
                  defaultValue=''
                />

                <SelectInput<Page, CssFilter>
                  options={FILTER_OPTIONS}
                  getOptionKey={String}
                  getOptionLabel={(filter) => tComp(`CommonSectionContent.filter.${filter}`)}
                  path={`${sectionPath}.filter2`}
                  label={tComp('CommonSectionContent.FormLabels.Filter2')}
                  defaultValue=''
                />

                <RadioInput<Page, SectionBackgroundPosition>
                  options={BACKGROUND_POSITION_OPTIONS}
                  getOptionKey={String}
                  getOptionLabel={(bg) => tComp(`CommonSectionContent.backgroundPosition.${bg}`)}
                  label={tComp('CommonSectionContent.FormLabels.BackgroundPosition')}
                  path={`${sectionPath}.backgroundPosition`}
                  direction='horizontal'
                  defaultValue='top'
                  required
                />

                <TextInput<Page>
                  path={`${sectionPath}.mediaCredits`}
                  label={tComp('CommonSectionContent.FormLabels.MediaCredits')}
                  defaultValue=''
                />
              </Container>
            </Container>

            <Container>
              <FinderButton
                type={FinderType.M24}
                finderProps={{
                  onSelectionConfirmed: (items: Array<GenericMedia<M24MediaModel>>) =>
                    items.forEach((item) => onChangeImage(prepareMediaItemAsM24MediaModel(item.source))),
                }}
                multiSelect={false}
              />
              <FinderButton
                type={FinderType.DM}
                finderProps={{
                  onSelectionConfirmed: (items: Array<GenericMedia<DMMediaModel>>) =>
                    items.forEach((item) => onChangeImage(prepareMediaItemAsDMMediaModel(item.source, item.url))),
                }}
                multiSelect={false}
              />
            </Container>

            <RadioInput<Page, SectionArrows>
              options={ARROW_OPTIONS}
              getOptionKey={String}
              getOptionLabel={(arrow) => tComp(`CommonSectionContent.arrows.${arrow}`)}
              label={tComp('CommonSectionContent.FormLabels.Arrows')}
              path={`${sectionPath}.arrows`}
              direction='horizontal'
              defaultValue='allways'
              required
            />

            <RadioInput<Page, SectionTextColor>
              options={TEXT_COLOR_OPTIONS}
              getOptionKey={String}
              getOptionLabel={(color) => tComp(`CommonSectionContent.textColor.${color}`)}
              label={tComp('CommonSectionContent.FormLabels.TextColor')}
              path={`${sectionPath}.txtColor`}
              direction='horizontal'
              defaultValue='light'
              required
            />
          </Container>
        </Collapse>
      </Container>
    </Section>
  );
};

export default CommonSectionContent;
